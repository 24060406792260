class DataCollector {
    constructor() {
        this.data = new Object();
    }

    recivedData(type, data) {
        console.log(type, data);
    }

    getEventFunction(type, data) {
        this.data[type] = data;
        this.recivedData(type, data);
    }

    getData(type){
        if(this.data[type]==undefined)
            this.data[type] = [];
        return this.data[type];
    }

    checkInputData(input) {
        let classObject = this;
        if(this.dataCollector != undefined)
            classObject = this.dataCollector;

        try {
            var event = JSON.parse(input.data);     
            classObject.getEventFunction(event.type, event.payload);
        } catch (err) {
            console.log('not an event', err);
        }
    }

}

export default DataCollector;