const iterator = require("./Iterator.js");

class ListView {
    constructor(id, parent, params) {
        this.header = [];
        this.data = [];
        this.buttons = [];
        this.id = id;
        this.prefix = "ListView";
        this.title = this.id;
        this.width = 1000;
        this.clickCallback;
        this.closeCallback;
        this.moveCallback = ()=>{};
        this.hideSearchBar = false;
        this.params = params;
        if(this.params==undefined){
            this.params= {};
        }else if(this.params.buttons!=undefined){
            this.buttons = this.params.buttons;
        }

        
        this.moduleName = this.prefix + "Module" + this.id;
        this.controllerName = this.prefix + "Controller" + this.id;
        this.searchModelId = this.prefix + "Wyszukiwarka" + this.id;
        this.divID = this.prefix + this.id;
        this.titleId = this.prefix + "Title" + this.id;
        this.parent = parent;
        this.startZIndexIterator = iterator;

        if (this.parent == undefined) {
            this.parent = document.getElementById("content");
        }
        if (this.parent == undefined) {
            document.body.innerHTML += `<div id="content"></div>`;
            this.parent = document.getElementById("content");
        }
        this.module = angular.module(this.moduleName, []);
        let classObject = this;
        this.module.directive('compileHtml', function ($compile) {
            return function (scope, element, attrs) {
              scope.$watch(
                function(scope) {
                  return scope.$eval(attrs.compileHtml);
                },
                function(value) {
                  element.html(value);
                  $compile(element.contents())(scope);
                }
              );
            };
          });
        this.module.controller(this.controllerName, function ($scope) {
            $scope.header = classObject.header;
            $scope.data = classObject.data;
            $scope.buttons = classObject.buttons;
            $scope.close = function () {
                if(classObject.closeCallback != undefined)
                    classObject.closeCallback(classObject);
                    classObject.delete();
            };
            $scope.rowClick = function (element) {
                if(classObject.clickCallback != undefined)
                    classObject.clickCallback(element, classObject);
                else
                    console.log(element);
            };

            $scope.headClick = function (element) {                
                let member = element;                
                if($scope.dynamicOrderFunction.substr(1) == member){
                    let char = $scope.dynamicOrderFunction.substr(0,1);
                    if(char=="+")
                        member = "-"+member;
                    else
                        member = "+"+member;
                }else{
                    member = "+"+member;
                }                

                $scope.dynamicOrderFunction = member;
                classObject.scope.$evalAsync();
            };

            $scope.dynamicOrderFunction = '';
            
            
            classObject.scope = $scope;
        });

        let child = document.createElement('div');
        child.innerHTML = this.getHTML();
        this.parent.appendChild(child);
        angular.bootstrap(document.getElementById(this.divID), [this.moduleName]);

    }

    setPosition(left, top){
        let div = document.getElementById(this.divID);
        div.style.left = left + 'px';
        div.style.top  = top + 'px';
    }

    move(){
        let div = document.getElementById(this.divID);
        let isDown = false;
        let offset = [0,0];
        div.style.position = "absolute";
        let classObject = this;

        div.addEventListener('mousedown', function(e) {
            isDown = true;
            div.style.zIndex = classObject.startZIndexIterator.next();
            offset = [
                div.offsetLeft - e.clientX,
                div.offsetTop - e.clientY
            ];
        }, true);
        
        div.addEventListener('mouseup', function(e) {
            isDown = false;
            classObject.moveCallback(classObject)
        }, true);
        
        div.addEventListener('mousemove', function(e) {

            if (isDown) {
                let mousePosition = {
            
                    x : event.clientX,
                    y : event.clientY
            
                };       
                div.style.left = (mousePosition.x + offset[0]) + 'px';
                div.style.top  = (mousePosition.y + offset[1]) + 'px';
            }            
        }, true);
    }

    setWidth(width){
        this.width = width;
        document.getElementById(this.divID).style.width = width + "px";
    }

    hideSearch(hide){
        if(hide==undefined)
         hide = false;
        this.hideSearchBar = hide;
        document.getElementById(this.searchModelId).style.display = this.hideSearchBar? "none": "default";
    }

    getHTML() {
        return `
        <div style="position: absolute; left:${this.params.left!=undefined? `${this.params.left}`: "0"}px; top:${this.params.top!=undefined? `${this.params.top}`: "0"}px; max-width:${this.params.maxWidth!=undefined? `${this.params.maxWidth}`: "1000"}px; z-index:${this.startZIndexIterator.next()}; width: ${this.width}px; ${this.params.height!=undefined? `height:${this.params.height}px;`: ""}" ng-controller="${this.controllerName}" id="${this.divID}">
        <div id="" style="background-color: #ffffff; border-color:#1247a3; border-style: solid; max-height:${this.params.fontSize!=undefined? `${this.params.maxHeight}`: "500"}px;">
            <div style="background-color: #1247a3; color: white;" ><h5 style="display: ${this.params.hidetitleMenuBar!=undefined? "none": "default"};margin-left: 10px;" id="${this.titleId}">${this.title}</h5></div>
            <button style="display: ${this.params.hideCloseButton!=undefined? "none": "default"};position:absolute; top:7px; right:10px;" ng-click="close()">X</button>
    
            <div class="">
                <div class="center-block" style="margin-right: 10px;">
                    <div style="margin-left: 10px; width: 98%; margin-top: ${this.params.hidetitleMenuBar!=undefined? "10px": "0"}" class="input-group">
                        <span class="input-group-addon"></span>
                        <input id="${this.searchModelId}" style="display: ${this.hideSearchBar? "none": "default"}" type="text" class="form-control" placeholder="Filtr"
                            ng-model="${this.searchModelId}">
                    </div>
    
                    <div class="table-responsive" style="max-height:${this.params.fontSize!=undefined? `${this.params.maxHeight-100}`: "400"}px; font-size: ${this.params.fontSize!=undefined? `${this.params.fontSize}`: "15"}px;">
                        <table class="table table-hover" style="border-color: #1247a3;">
                            <thead>
                                <tr>
                                    <th style="cursor:pointer;" ng-repeat="(key, value) in header" ng-click="headClick(value);" compile-html="naglowek">{{key}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr ng-repeat="element in data | filter:${this.searchModelId} | orderBy:dynamicOrderFunction" id="{{element.id}}">
                                    <td ng-repeat="(key, value) in element" ng-click="rowClick({'object':element, 'key':key, 'value':value});" compile-html="value">{{value | number}}</td>
                                </tr>
                                <th>
                                </th>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div id="additionalInfo${this.id}" style="float: left;display: inline-block;"></div><div ng-repeat="button in buttons" style="float: right;display: inline-block;">
        <button type="button" ng-repeat="(key, value) in button" ng-click="rowClick({'object':button, 'key':key, 'value':value});">{{value}}</button> 
    </div>

    </div>`;
    }

    updateData(data) {
        this.data = data;
        this.scope.data = this.data;
        this.scope.$apply();
    }

    setTitle(title) {
        this.title = title;
        document.getElementById(this.titleId).innerHTML = title;
    }

    setHeader(header) {
    //    console.log(eval(header[3]))
        
        this.header = header;

        
        this.scope.header = header;
        this.scope.$apply();
    }
    delete() {
        let element = document.getElementById(this.divID);
        element.parentNode.removeChild(element);
    }

}

export default ListView;