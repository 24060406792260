const iterator = require("./Iterator.js");

class ContextMenuView {
    constructor(id, parent) {
        this.data = [];
        this.id = id;
        this.prefix = "ContextMenuView";
        this.title = this.id;
        this.clickCallback;
        this.closeCallback;
        this.left = 100;
        this.top = 10;
        this.width = undefined;        
        this.moduleName = this.prefix + "Module" + this.id;
        this.controllerName = this.prefix + "Controller" + this.id;
        this.divID = this.prefix + this.id;
        this.parent = parent;

        this.startZIndexIterator = iterator;
        if (this.parent == undefined) {

            this.parent = document.getElementById("content");
        }
        if (this.parent == undefined) {
            document.body.innerHTML += `<div id="content"></div>`;
            this.parent = document.getElementById("content");
        }

        this.module = angular.module(this.moduleName, []);
        let classObject = this;
        this.module.directive('compileHtml', function ($compile) {
            return function (scope, element, attrs) {
              scope.$watch(
                function(scope) {
                  return scope.$eval(attrs.compileHtml);
                },
                function(value) {
                  element.html(value);
                  $compile(element.contents())(scope);
                }
              );
            };
          });
        this.module.controller(this.controllerName, function ($scope) {
            $scope.data = classObject.data;
            $scope.close = function () {
                if(classObject.closeCallback != undefined)
                    classObject.closeCallback(classObject);
                    classObject.delete();
            };
            $scope.click = function (element) {
                if(classObject.clickCallback != undefined)
                    classObject.clickCallback(element, classObject);
                else
                    console.log(element);
            };
            classObject.scope = $scope;
        });
        let child = document.createElement('div');

        child.innerHTML = this.getHTML();
        this.parent.appendChild(child);
        angular.bootstrap(document.getElementById(this.divID), [this.moduleName]);

    }

    setPosition(left, top){
        let div = document.getElementById(this.divID);
        this.left = left;
        this.top = top;
        div.style.left = left + 'px';
        div.style.top  = top + 'px';
    }

    setLeft(left){
        this.left = left;
        document.getElementById(this.divID).style.left = left + "px";
    }

    setTop(top){
        this.top = top;
        document.getElementById(this.divID).style.top = top + "px";
    }

    setWidth(width){
        this.width = width;
        document.getElementById(this.divID).style.width = width + "px";
    }

    // 'div': ng-click="click($event.currentTarget,
    getHTML() {
        return `
        <div style="position:absolute; left: ${this.left}px; top: ${this.top}px; background-color: lightgrey; z-index:${this.startZIndexIterator.next()}; width: ${this.width}px; border-style: solid;" ng-controller="${this.controllerName}" id="${this.divID}">
            <div ng-click="click({'object':pos, 'key':key, 'value':value});" style="padding-left:5px;padding-right:5px;" ng-repeat="pos in data" compile-html="pos.title" onMouseOver="this.style.fontWeight='bold';this.style.cursor='pointer';this.style.backgroundColor='#eee';"
            onMouseOut="this.style.fontWeight='normal';this.style.backgroundColor='lightgrey'"></div>
        </div>`;
    }

    updateData(data) {
        this.data = data;
        //console.log("updateData", data)
        this.scope.data = this.data;
        this.scope.$apply();
    }

    delete() {
        let element = document.getElementById(this.divID);
        element.parentNode.removeChild(element);
        delete this;
    }

}

export default ContextMenuView;