class Iterator{
    constructor(){
        this.current = 0;        
    }
    next(){
        this.current++;
        return this.current;
    }
        
    static getInstance() {
        if (!this.INSTANCE) {
            this.INSTANCE = new Iterator();
        }
        return this.INSTANCE;
    }
}

module.exports = Iterator.getInstance();