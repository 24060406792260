const iterator = require("./Iterator.js");



class CalendarView {
    constructor(id, parent, params) {
        this.header = [];
        this.data = [];
        this.buttons = [];
        this.id = id;
        this.prefix = "LoginBox";
        this.title = this.id;
        this.width = 300;
        this.clickCallback;
        this.closeCallback;
        this.moveCallback = () => { };
        this.hideSearchBar = false;
        this.params = params;
        if (this.params == undefined) {
            this.params = {};
        } else if (this.params.buttons != undefined) {
            this.buttons = this.params.buttons;
        }

        this.moduleName = this.prefix + "Module" + this.id;
        this.controllerName = this.prefix + "Controller" + this.id;
        this.searchModelId = this.prefix + "Wyszukiwarka" + this.id;
        this.divID = this.prefix + this.id;
        this.titleId = this.prefix + "Title" + this.id;
        this.parent = parent;
        this.startZIndexIterator = iterator;

        if (this.parent == undefined) {
            this.parent = document.getElementById("content");
        }
        if (this.parent == undefined) {
            document.body.innerHTML += `<div id="content"></div>`;
            this.parent = document.getElementById("content");
        }
        this.module = angular.module(this.moduleName, []);
        let classObject = this;
        this.module.directive('compileHtml', function ($compile) {
            return function (scope, element, attrs) {
                scope.$watch(
                    function (scope) {
                        return scope.$eval(attrs.compileHtml);
                    },
                    function (value) {
                        element.html(value);
                        $compile(element.contents())(scope);
                    }
                );
            };
        });
        this.module.controller(this.controllerName, function ($scope) {
            $scope.header = classObject.header;
            $scope.data = classObject.data;
            $scope.buttons = classObject.buttons;
            $scope.close = function () {
                if (classObject.closeCallback != undefined)
                    classObject.closeCallback(classObject);
                classObject.delete();
            };
            $scope.rowClick = function (element) {

                if (classObject.clickCallback != undefined) {
                    element["searchBox"] = {
                        text: document.getElementById(`text${classObject.id}`).value,
                        dateFrom: document.getElementById(`dateFrom${classObject.id}`).value,
                        dateTo: document.getElementById(`dateTo${classObject.id}`).value
                    }
                    classObject.clickCallback(element, classObject, params);
                } else {
                    console.log(element);
                }
            };
            classObject.scope = $scope;
        });
        let child = document.createElement('div');
        child.innerHTML = this.getHTML();
        this.parent.appendChild(child);
        angular.bootstrap(document.getElementById(this.divID), [this.moduleName]);
        this.setDate();
    }



    setDate(newDate) {
        let date = new Date();

        if (newDate != undefined) {
            date = new Date(newDate);
        }
        let monthMap = [
            'Styczeń',
            'Luty',
            'Marzec',
            'Kwiecień',
            'Maj',
            'Czerwiec',
            'Lipiec',
            'Sierpień',
            'Wrzesień',
            'Październik',
            'Listopad',
            'Grudzień'
        ];

        let dayMap = [
            'Niedziela',
            'Poniedziałek',
            'Wtorek',
            'Środa',
            'Czwartek',
            'Piątek',
            'Sobota'
        ];

        let inputDocEle = document.getElementById(this.id + "calendarInputField");
        this.inputDocEle = inputDocEle;
        if (inputDocEle.valueAsDate == undefined)
            inputDocEle.valueAsDate = date;
        //alert(inputDocEle.valueAsDate)
        //alert(inputDocEle.valueAsDate.getDay())
        document.getElementById(this.id + "calendarData").innerHTML = `<strong>${dayMap[inputDocEle.valueAsDate.getDay()]}</strong>`
        document.getElementById(this.id + "calendarHeader").innerHTML = `${monthMap[inputDocEle.valueAsDate.getMonth()]} - ${inputDocEle.valueAsDate.getFullYear()}`;



        let classObject = this;
        function clk() {
            classObject.setDate(date);

        }


        document.getElementById(this.id + "calendarInputField").onchange = clk;
        if (classObject.clickCallback != undefined) {
            classObject.clickCallback(inputDocEle.valueAsDate);
        }
    }

    setPosition(left, top) {
        let div = document.getElementById(this.divID);
        div.style.left = left + 'px';
        div.style.top = top + 'px';
    }

    move() {
        let div = document.getElementById(this.divID);
        let isDown = false;
        let offset = [0, 0];
        div.style.position = "absolute";
        let classObject = this;

        div.addEventListener('mousedown', function (e) {
            isDown = true;
            div.style.zIndex = classObject.startZIndexIterator.next();
            offset = [
                div.offsetLeft - e.clientX,
                div.offsetTop - e.clientY
            ];
        }, true);

        div.addEventListener('mouseup', function (e) {
            isDown = false;
            classObject.moveCallback(classObject)
        }, true);

        div.addEventListener('mousemove', function (e) {

            if (isDown) {
                let mousePosition = {

                    x: event.clientX,
                    y: event.clientY

                };
                div.style.left = (mousePosition.x + offset[0]) + 'px';
                div.style.top = (mousePosition.y + offset[1]) + 'px';
            }
        }, true);
    }

    setWidth(width) {
        this.width = width;
        document.getElementById(this.divID).style.width = width + "px";
    }

    getHTML() {
        return `
        <div style="position: absolute; left:${this.params.left != undefined ? `${this.params.left}` : "0"}px; top:${this.params.top != undefined ? `${this.params.top}` : "0"}px; max-width:${this.params.maxWidth != undefined ? `${this.params.maxWidth}` : "1000"}px; z-index:${this.startZIndexIterator.next()}; width: ${this.width}px; " ng-controller="${this.controllerName}" id="${this.divID}">
        <div id="${this.id}border" style="background-color: #ffffff; border-color:#1247a3; border-style: solid; max-height:${this.params.maxHeight != undefined ? `${this.params.maxHeight}` : "100"}px;">
           
    <h6 style="text-align:center; margin-top: 10px;" id="${this.titleId}">${this.title}</h6>
            <div class="">
                <div class="center-block" style="margin-right: 10px; margin-left:10px; margin-bottom: 10px;">
                    <center>
                    <h3 id="${this.id}calendarHeader"></h3>
<div id="${this.id}calendarData"></div>
<input style="width: 216px; margin-top: 2px; text-align: center; background-color: #cce7ff; text-color: black; display:inline-block;" type="date" id="${this.id}calendarInputField">

                    </center>
                    <div ng-repeat="button in buttons" style="margin-top: 5px; margin-right: 28px; float: right;display: inline-block;">
                    <button type="button" ng-repeat="(key, value) in button" ng-click="rowClick({'object':button, 'key':key, 'value':value});">{{value}}</button> 
                </div>
            </div>
        </div>
        <div style="margin-left: 35px; margin-bottom: 10px; height: 20px" id="additionalInfo${this.id}"></div>

    </div>

    </div>`;
    }


    setTitle(title) {
        this.title = title;
        document.getElementById(this.titleId).innerHTML = title;
    }

    delete() {
        let element = document.getElementById(this.divID);
        element.parentNode.removeChild(element);
    }

}

export default CalendarView;