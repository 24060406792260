import ListView from './ListView.js';
import LoginBox from './LoginBox.js';
import FileUpload from './FileUpload.js';
import CalendarView from './CalendarView.js';
import ExtendedSearchBox from './ExtendedSearchBox.js';
import DataCollector from './DataCollector.js';
let dataCollector = new DataCollector();
import ServerConnection from './ServerConnection.js';
let sc = new ServerConnection();
dataCollector.recivedData = () => { };
let title = "Kurier rzgów";
import ContextMenuView from './ContextMenuView.js';
let listaDowyplatyCounter = 0;
let error = "";
let movable = false;

let wWitdth = window.innerWidth;
let wHeight = window.innerHeight;
let top1 = 173;
let fontSi1 = 10;
let viewConfigs = {
    ListaPaczki:{
        id: 'ListaPaczki',
        fontSize: fontSi1,
        maxHeight: wHeight-top1-10,
        maxWidth: wWitdth/2,
        hideCloseButton: true,
        buttons: [{ 'buttonDoWyplaty': 'Do wypłaty' }, { 'buttonNastwypl': 'Nast Wypł' }, { 'buttonZanaczWszystko': 'Zaznacz wszystko' }],
        left: 0,
        top: top1
    },
    ListaWyplaconych: {
        id: 'ListaWyplaconych',
        fontSize: fontSi1,
        maxHeight: wHeight/2-10,
        maxWidth: wWitdth/2,
        hideCloseButton: true,
        left: wWitdth/2-2,
        top: 0
    },
    ListaDoWyplaty: {
        id: 'ListaDoWyplaty',
        fontSize: fontSi1,
        maxHeight: wHeight/2-10,
        maxWidth: wWitdth/2,
        hideCloseButton: true,
        buttons: [{ 'buttonWyplac': 'Wypłać' }],
        left: wWitdth/2-2,
        top: wHeight/2
    },
    Menu:{
        id: 'Menu',
        fontSize: 15,
        maxHeight: top1*2,
        maxWidth: 150,
        hideCloseButton: true,
        buttons: [],
        left: 0,
        top: 0
    },
    SzukanieZaawansowane:{
         buttons: [{ "searchButton": "Szukaj" }], left: 150-5, top: 0, maxHeight: top1
        },
        Kalendarz:{ left: 443, top: 0, maxHeight: top1 }
}

let selectedDoWyplaty = [];

let openedViews = {};

let headerMap1 = {
    'Referencje': 'notes',
    'Data nadania': 'senttime',
    'Data odbioru': 'receivetime',
    'COD': 'cod',
    'Odbiorca': 'receiver',
    'Numer referencyjny': 'referencenumber',
    'Poddex': 'poddex',
    'Pobranie': 'amountondelivery',
    'Marza': 'income',
    'Miasto': 'receivercity',
    'Ulica': 'receiverstreet',
    'Id': 'id',
    'Grupa': 'group',
};

let headerMap3 = {
    'Referencje': 'notes',
    'Data nadania': 'senttime',
    'Data odbioru': 'receivetime',
    'COD': 'cod',
    'Odbiorca': 'receiver',
    'Numer referencyjny': 'referencenumber',
    'Poddex': 'poddex',
    'Pobranie': 'amountondelivery',
    'Marza': 'income',
    'Miasto': 'receivercity',
    'Ulica': 'receiverstreet',
    'Id': 'id',
    'Pakiet': 'grouppackages'
};

let headerMap2 = {
    'Data wypłaty': "withdrawdata",
    'Numer wypłaty': "withdrawnumber",
    'Wyplacone przez': "withdrawedby",
    'Referencje': 'notes',
    'Data nadania': 'senttime',
    'Data odbioru': 'receivetime',
    'COD': 'cod',
    'Odbiorca': 'receiver',
    'Numer referencyjny': 'referencenumber',
    'Poddex': 'poddex',
    'Pobranie': 'amountondelivery',
    'Marza': 'income',
    'Miasto': 'receivercity',
    'Ulica': 'receiverstreet',
    'Id': 'id'
};

function openWyplaty() {

    //ListView paczki
    let lvPaczkiParams = viewConfigs['ListaPaczki'];
    if (openedViews[lvPaczkiParams.id] == undefined) {

        let lvPaczki = new ListView(lvPaczkiParams.id, undefined, lvPaczkiParams);
        lvPaczki.setWidth(10000)
        openedViews[lvPaczkiParams.id] = lvPaczki;

        let ta= [];
        Object.keys(headerMap3).forEach((key)=>{
            let t = Object.assign({}, {});
            t[key] = headerMap3[key]
            ta.push(t)
        });

        lvPaczki.setHeader(headerMap3);
        if (movable) {
            lvPaczki.move();
            lvPaczki.moveCallback = (ss) => {
            }
        }
        lvPaczki.setTitle("Lista nierozliczonych");

        lvPaczki.updateData([]);
        let clicked = 0;

        lvPaczki.clickCallback = (params) => {
            if (params.object != undefined && params.object.id != undefined) {
                clicked++;
                setTimeout(() => {
                    if (clicked == 2) {
                        if(params.key != 'grouppackages' || params.object.grouppackages.split("\n").length == 1){
                        let newNotes = prompt(`Edytuj paczkę ${params.object.id}\n${params.object['notes']}\t\t\t`, params.object['notes'])
                        if(newNotes!= null && newNotes != params.object['notes']){
                            if(confirm(`Czy napewno zapisać:\n\n\t${newNotes}\n\nzamiast:\n\n\t${params.object['notes']}\n\n?\n`)){
                            //console.log(params.object.id, newNotes)
                            sc.editPaczka({id: params.object.id, notes: newNotes}, (ret)=>{
                                if (ret.response.status == 200) {
                                    getPaczki();
                                    closeView("contextMenu");
                                }
                            })
                        }
                        }else{
                            alert(`Wartość niezmieniona!`)
                        }
                    }else{
                        if(confirm(`Czy rozdzielić pakiet?\n${params.object.notes}\n${params.object.referencenumber} (${params.object.id})`)){
                            function isNormalInteger(str) {
                                return /^\+?(0|[1-9]\d*)$/.test(str);
                            }
                               
                                let o = {
                                    id: params.object.referencenumber,
                                    to: prompt('Na ile pakietów rozdzielić: ', 2)
                                }
                                if(o.to!=null){

                                    if(isNormalInteger(o.to) && Number(o.to)>0){
                                sc.splitPaczki(o, (ret)=>{
                                    if (ret.response.status == 200) {
                                        getPaczki();
                                    }
                                })
                            }else{
                                alert(`Wymagana wartość: 'liczba całkowita > 0' !`)
                            }

                            }


                        }
                    }

                    }
                    clicked = 0;
                }, 200)

                let colorA = '#005566';
                let colorB = '#ffffff';
                if (document.getElementById(params.object.id).style.backgroundColor != "rgb(0, 85, 102)") {
                    document.getElementById(params.object.id).style.backgroundColor = colorA;
                    document.getElementById(params.object.id).style.color = "white";
                    selectedDoWyplaty.push(params.object.id);
                } else {

                    selectedDoWyplaty.splice(selectedDoWyplaty.indexOf(params.object.id), 1);
                    document.getElementById(params.object.id).style.color = "black";

                    document.getElementById(params.object.id).style.backgroundColor = colorB;
                }

            } else if (params.key != undefined) {
                if (params.key == "buttonDoWyplaty" || params.key == "buttonNastwypl") {
                    let newSelectedDoWyplaty = [];
                    selectedDoWyplaty.forEach((element) => {
                        if (document.getElementById(element) != undefined && document.getElementById(element).style.backgroundColor == "rgb(0, 85, 102)") {
                            newSelectedDoWyplaty.push(element);
                        }
                    })
                    selectedDoWyplaty = newSelectedDoWyplaty;
                    dodajDoWyplaty(selectedDoWyplaty, params.key == "buttonNastwypl")

                } else if (params.key == "buttonZanaczWszystko") {
                    let newSelectedDoWyplaty = [];
                    dataCollector.getData("paczkiNierozliczone").forEach((element) => {
                        newSelectedDoWyplaty.push(element.id);
                        selectedDoWyplaty = newSelectedDoWyplaty;
                        document.getElementById(element.id).style.backgroundColor = "rgb(0, 85, 102)";
                    })
                }
                recalculateSum();
            }
        }
    }
    document.getElementById("additionalInfo" + lvPaczkiParams.id).innerHTML = "<strong>Pozostało do wypłaty: 0.00</strong>";


    //End of ListView paczki

    //ListView Lista wypłaconych
    let lvListaWyplaconychParams = viewConfigs['ListaWyplaconych'];
    if (openedViews[lvListaWyplaconychParams.id] == undefined) {

        let lvListaWyplaconych = new ListView(lvListaWyplaconychParams.id, undefined, lvListaWyplaconychParams);
        openedViews[lvListaWyplaconychParams.id] = lvListaWyplaconych;

        lvListaWyplaconych.setHeader(headerMap2);
        if (movable) {
            lvListaWyplaconych.move();
            lvListaWyplaconych.moveCallback = (ss) => {
                ;
            }
        }
        //lvListaWyplaconych.hideSearch(true)
        lvListaWyplaconych.setTitle("Lista wypłaconych");
        lvListaWyplaconych.updateData([]);


        lvListaWyplaconych.clickCallback = (ss) => {
            openContextMenu(ss.object.id);
            // document.getElementById(ss.object.id).style.backgroundColor = '#005566';
        }

        document.getElementById("additionalInfo" + lvListaWyplaconychParams.id).innerHTML = "<strong>Suma wypłat: 0.00</strong>";

    }
    //End of ListView Lista wypłaconych


    //ListView Lista do wypłaty
    let lvListaDoWyplatyParams = viewConfigs['ListaDoWyplaty'];
    if (openedViews[lvListaDoWyplatyParams.id] == undefined) {

        let lvListaDoWyplaty = new ListView(lvListaDoWyplatyParams.id, undefined, lvListaDoWyplatyParams);
        openedViews[lvListaDoWyplatyParams.id] = lvListaDoWyplaty;

        lvListaDoWyplaty.setHeader(headerMap1);
        if (movable) {
            lvListaDoWyplaty.move();
            lvListaDoWyplaty.moveCallback = (ss) => {
                console.log(ss)
            }
        }
        //lvListaDoWyplaty.hideSearch(true)
        lvListaDoWyplaty.setTitle("Lista do wypłaty");
        lvListaDoWyplaty.updateData([]);

        document.getElementById("additionalInfo" + lvListaDoWyplatyParams.id).innerHTML = "<strong>Suma: 0.00</strong>";

        let clicked = 0;

        lvListaDoWyplaty.clickCallback = (ss) => {
            clicked++;

            if (ss.key != "buttonWyplac") {
                setTimeout(() => {
                    if (clicked == 2) {
                        let current1 = dataCollector.getData("paczkiDoWyplaty");
                        let element;
                        let current2 = dataCollector.getData("paczkiNierozliczone");
                        current1.forEach((e) => {
                            if (e.id == ss.object.id)
                                element = e;
                        })

                        deleteFromWyplaty([element])
                        current2.push(element);
                        dataCollector.getEventFunction("paczkiNierozliczone", current2);
                        openedViews["ListaPaczki"].updateData(dataCollector.getData("paczkiNierozliczone"));
                        openedViews["ListaDoWyplaty"].updateData(dataCollector.getData("paczkiDoWyplaty"));
                        recalculateSum();

                    }
                    clicked = 0;
                }, 200)
            } else {
                let ids = [];
                let groups = {};

                dataCollector.getData("paczkiDoWyplaty").forEach((p) => {
                    ids.push(p.id)
                    if (groups[p.group] == undefined)
                        groups[p.group] = [];
                    groups[p.group].push(p.id)
                })

                sc.withdrawnPaczki(groups, (ret) => {

                    //console.log(ids, ret.response.status)
                    if (ret.response.status == 200) {
                        dataCollector.getEventFunction("paczkiDoWyplaty", []);
                        openedViews["ListaDoWyplaty"].updateData([]);
                        getPaczki();
                        sc.getPDF({groups: JSON.stringify(groups)})
                    }
                })

            }
            // document.getElementById(ss.object.id).style.backgroundColor = '#005566';
        }
    }
    //End of ListView Lista do wypłaty

    //ListView Menu
    let lvMenuParams = viewConfigs["Menu"];
    if (openedViews[lvMenuParams.id] == undefined) {

        let lvMenu = new ListView(lvMenuParams.id, undefined, lvMenuParams);
        openedViews[lvMenuParams.id] = lvMenu;
        lvMenu.hideSearch(true);
        lvMenu.updateData([{ 'menuWyplaty': 'Wypłaty' }, { 'menuRaporty': 'Raporty' }]);
        lvMenu.clickCallback = (param) => {
            if (param.key == "menuRaporty") {
                closeWyplaty();
                openImportRaportow();
            } else if (param.key == "menuWyplaty") {
                openWyplaty();
                closeImportRaportow();
            }
        };
    }
    //End of ListView Menu


    //CalendarView
    if (openedViews["Kalendarz"] == undefined) {

        let cvCalendar = new CalendarView("Kalendarz", undefined, viewConfigs["Kalendarz"]);
        cvCalendar.clickCallback = (e) => {
            document.getElementById(`Kalendarzborder`).style.borderColor= 'green';
            document.getElementById(`SzukanieZaawansowaneborder`).style.borderColor= '#1247a3';
            getPaczki({ dateFrom: e, dateTo: e });
        }
        openedViews["Kalendarz"] = cvCalendar;
        if (movable) {
            cvCalendar.move(true)
        }
    }
    //End of Calendarview
    if (openedViews["SzukanieZaawansowane"] == undefined) {

        let esbvSearchBox = new ExtendedSearchBox("SzukanieZaawansowane", undefined, viewConfigs['SzukanieZaawansowane']);
        openedViews["SzukanieZaawansowane"] = esbvSearchBox;

        esbvSearchBox.setTitle("Szukanie zaawansowane")
        if (movable) {
            esbvSearchBox.move(true)
        }

        esbvSearchBox.clickCallback = (e, c, d) => {
            getPaczki(e["searchBox"]);
            document.getElementById(`Kalendarzborder`).style.borderColor= '#1247a3';
            document.getElementById(`SzukanieZaawansowaneborder`).style.borderColor= 'green';


        };

    }
    let nd = new Date();
    getPaczki({ dateTo: nd, dateFrom: nd });
    showPaczkiInView();

}

let cred;

function openLoginBox() {
    //LoginBox
    let id = "LoginBox";
    if (openedViews[id] == undefined) {
        let lbvLoginBox = new LoginBox(id, undefined, { maxWidth: 360, buttons: [{ "loginButton": "Zaloguj" }], left: ((window.innerWidth / 2) - (360 / 2)), top: ((window.innerHeight / 2) - 100) });
        openedViews[id] = lbvLoginBox;
        lbvLoginBox.setTitle(title)

        if (movable) {
            lbvLoginBox.move();
        }
        lbvLoginBox.clickCallback = (e, r, t) => {
            let user = t.login;
            let pass = t.password;
            if (user == "" || pass == "") {
                document.getElementById("additionalInfo" + id).innerHTML = `<div style="color:red; font-weight: bold; margin-bottom:5px;">Podaj login i hasło!</div>`;
            } else {
                document.getElementById("additionalInfo" + id).innerHTML = "";
                checkUserNameAndPassword(user, pass, (ret, res) => {
                    if (ret != undefined) {
                        cred = ret;
                        closeView(id)
                        openWyplaty();
                    } else {
                        if(res.status==401){
                           document.getElementById("additionalInfo" + id).innerHTML = `<div style="color:red; font-weight: bold; margin-bottom:5px;">Błędny login lub hasło!</div>`;
                        }else{
                            document.getElementById("additionalInfo" + id).innerHTML = `<div style="color:red; font-weight: bold; margin-bottom:5px;">Błąd połączenia z serwerem!</div>`;
                        }
                    }
                });

            }
        }
        //End of LoginBox
    }
}

function closeView(id) {
    if (openedViews[id] != undefined) {
        openedViews[id].delete();
        openedViews[id] = undefined;
    }
}

function closeWyplaty() {

    closeView('SzukanieZaawansowane');
    closeView('Kalendarz');
    closeView('ListaDoWyplaty');
    closeView('ListaWyplaconych');
    closeView('ListaPaczki');

}

function closeImportRaportow() {
    closeView('ImportRaportow');
}

function openImportRaportow() {
    let name = "ImportRaportow";
    if (openedViews[name] == undefined) {
        let fuv = new FileUpload(name, undefined, { cred: cred, left: ((window.innerWidth / 2) - (360)), top: ((window.innerHeight / 3)) });
        openedViews[name] = fuv;
        if(movable)
            fuv.move(true)
        fuv.setTitle("Importuj raport")
        fuv.fileLoadedCallback = (content, fileName) => {
            document.getElementById(`additionalInfo${name}`).innerHTML = ``;
            sc.uploadFile(content, (res) => {
                if (res != undefined && res.response != undefined && res.response.status == 200) {
                    if (res.message == undefined)
                        res.message = "";
                    document.getElementById(`additionalInfo${name}`).innerHTML = `<div style="color:green">Zaimportowano pomyślnie. ${res.message}</div>`;
                } else {
                    if (res.error == undefined)
                        res.error = "";
                    document.getElementById(`additionalInfo${name}`).innerHTML = `<div style="color:red">Wystąpił błąd podczas importu! ${res.error}</div>`;
                }

            },fileName, ()=>{
                document.getElementById(`additionalInfo${name}`).innerHTML = `<div style="color:black">Wczytywanie raportu...</div>`;
            })
        }
    }
}


function checkUserNameAndPassword(username, password, callback) {
    sc.login(username, password, callback);

}

let lastParams;

function getPaczki(filter, callback) {
    if (filter == undefined) {
        filter = lastParams;
    } else {
        if(filter['dateFrom']!=undefined){
            filter['dateFrom'] = convertDateFormat(filter['dateFrom']);
        }
        if(filter['dateTo']!=undefined){
            filter['dateTo'] = convertDateFormat(filter['dateTo']);
        }
        if( filter['dateTo']==null){
            delete filter['dateTo'];
        }
        if( filter['dateFrom']==null){
            delete filter['dateFrom'];
        }
        lastParams = filter;
    }
    console.log("filter", filter)
    sc.getPaczki(filter, (ret) => {

        //let paczki = require('./exampleData.js');

        let paczki = JSON.parse(ret)["data"];
        //console.log(paczki, ret)
        let parsedData = [];
        let parsedData2 = [];
        let i = 0, j = 0;
        //console.log(filter)
        paczki.forEach((element, i) => {

            let obj = Object.assign({}, {});
            Object.keys(headerMap3).forEach((key) => {
                if (element[headerMap3[key]] != undefined) {
                    obj[headerMap3[key]] = element[headerMap3[key]];
                } else {
                    obj[headerMap3[key]] = "";
                }
            })
            if (!element["iswithdrawn"]){
                parsedData.push(convertObjectDatesFormat(obj));
            }
        });

        paczki.forEach((element, i) => {

            let obj = Object.assign({}, {});
            Object.keys(headerMap2).forEach((key) => {
                if (element[headerMap2[key]] != undefined) {
                    obj[headerMap2[key]] = element[headerMap2[key]];
                } else {
                    obj[headerMap2[key]] = "";
                }
            })
            if (element["iswithdrawn"]){
                parsedData2.push(convertObjectDatesFormat(obj));
            }
        });

        dataCollector.getEventFunction("paczkiNierozliczone", parsedData);
        dataCollector.getEventFunction("paczkiWyplacone", parsedData2);


        showPaczkiInView();
        if (callback != undefined)
            callback();
    })
}

function showPaczkiInView() {
    recalculateSum();
    if (openedViews["ListaPaczki"] != undefined) {

        let temp = dataCollector.getData("paczkiNierozliczone");
        temp.forEach((e)=>{
            let count = 0;
            let alt = "";
            e['grouppackages'].split("\n").forEach((h)=>{
                if(h!=""){
                    count++;
                    alt+=h;
                }
            })
            if(count==0){
                count = 1;
                e['grouppackages']=`<div title="${e['referencenumber']}">${count}</div>`;
            }else{
                e['grouppackages']=`<div title="${e['grouppackages']}">${count}</div>`;
            }
        })

        openedViews["ListaPaczki"].updateData(temp);
    }
    if (openedViews["ListaWyplaconych"] != undefined) {
        openedViews["ListaWyplaconych"].updateData(dataCollector.getData("paczkiWyplacone"));
    }
    if (openedViews["ListaDoWyplaty"] != undefined) {

        openedViews["ListaDoWyplaty"].updateData(dataCollector.getData("paczkiDoWyplaty"));
    }
}

function dodajDoWyplaty(selectedDoWyplaty, nowy) {
    let array = dataCollector.getData("paczkiNierozliczone");
    let elementsToMove = [];
    selectedDoWyplaty.forEach((element) => {
        let index = -1;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id == element) {
                elementsToMove.push(array[i]);
                index = i;
                break;
            }
        }
        if (index > -1) {
            array.splice(index, 1);
        }
    })
    dataCollector.getEventFunction("paczkiNierozliczone", array)
    // openedViews["ListaPaczki"].updateData(dataCollector.getData("paczkiNierozliczone"));

    addToDoWyplaty(elementsToMove, nowy);
    openedViews["ListaDoWyplaty"].updateData(dataCollector.getData("paczkiDoWyplaty"));
    elementsToMove.forEach((etm) => {
        let index = -1;
        let element;
        for (let i = 0; i < dataCollector.getData("paczkiDoWyplaty").length; i++) {
            if (dataCollector.getData("paczkiDoWyplaty")[i].id == etm.id) {
                index = i;
                element = dataCollector.getData("paczkiDoWyplaty")[i];
                break;
            }
        }
        let colorA = '#f0ff4d';
        let colorB = '#47daff';
        setTimeout(() => {
            if (index >= 0 && parseInt(element.group) % 2 == 0) {
                document.getElementById(etm.id).style.backgroundColor = colorA;
            } else {
                document.getElementById(etm.id).style.backgroundColor = colorB;
            }
        }, 500)
    })
}

let groupsInfo = {};

function addToDoWyplaty(elements, nowy) {
    let nArray = dataCollector.getData("paczkiDoWyplaty");
    if (nArray == undefined)
        nArray = [];
    let grupa = listaDowyplatyCounter;
    if (grupa == 0 || nowy) {
        grupa++;
    }
    elements.forEach((element) => {
        let nElement = {};
        Object.keys(headerMap2).forEach((mapElement) => {
            if (element[headerMap2[mapElement]] != undefined) {
                nElement[headerMap2[mapElement]] = element[headerMap2[mapElement]]
            }
        })
        nElement["group"] = grupa;
        nArray.push(nElement);
        groupsInfo[nElement.id] = grupa;
    })
    dataCollector.getEventFunction("paczkiDoWyplaty", nArray)
    listaDowyplatyCounter = grupa;
}

function recalculateSum() {
    let paczkiDoWyplaty = dataCollector.getData("paczkiDoWyplaty");
    let paczkiWyplacone = dataCollector.getData("paczkiWyplacone");
    let paczkiNierozliczone = dataCollector.getData("paczkiNierozliczone");
    let nierozliczonesuma = parseFloat('0.00');
    let paczkiDoWyplatysuma = parseFloat('0.00');
    let paczkiWyplaconeSuma = parseFloat('0.00');
    paczkiNierozliczone.forEach((e) => {
        if (e != undefined && e.income != undefined && e.income != "")
            nierozliczonesuma += getSumforCustomer(e['notes']);
    })
    document.getElementById("additionalInfo" + "ListaPaczki").innerHTML = "<strong>Pozostało do wypłaty: " + nierozliczonesuma.toFixed(2) + `<div style="display: inline; text-align:center; margin-left: 100px;">Paczek nierozliczoncyh: ${paczkiNierozliczone.length}</div>` + "</strong>";
    if (paczkiDoWyplaty != undefined) {
        paczkiDoWyplaty.forEach((e) => {
            if (e != undefined && e.income != undefined && e.income != "")
                paczkiDoWyplatysuma += getSumforCustomer(e['notes']);;
        })
        document.getElementById("additionalInfo" + "ListaDoWyplaty").innerHTML = "<strong>Suma: " + paczkiDoWyplatysuma.toFixed(2)+ `<div style="display: inline; text-align:center; margin-left: 100px;">Paczek do wypłaty: ${paczkiDoWyplaty.length}</div>` + "</strong>";
    }


    if (paczkiWyplacone != undefined) {
        paczkiWyplacone.forEach((e) => {
            if (e != undefined && e.income != undefined && e.income != "")
                paczkiWyplaconeSuma += getSumforCustomer(e['notes']);;
        })
        document.getElementById("additionalInfo" + "ListaWyplaconych").innerHTML = "<strong>Suma wypłat: " + paczkiWyplaconeSuma.toFixed(2)+ `<div style="display: inline; text-align:center; margin-left: 100px;">Paczek wypłaconych: ${paczkiWyplacone.length}</div>`  + "</strong>";
    }


}

function deleteFromWyplaty(elements) {
    let nArray;
    let oldArray = dataCollector.getData("paczkiDoWyplaty");
    if (nArray == undefined)
        nArray = [];
    oldArray.forEach((element) => {
        let nElement = {};
        Object.keys(headerMap2).forEach((mapElement) => {
            if (element[headerMap2[mapElement]] != undefined) {
                nElement[headerMap2[mapElement]] = element[headerMap2[mapElement]]
            }
        })
        let jest = false;
        nElement["group"] = groupsInfo[nElement.id];

        elements.forEach((e1) => {
            if (!jest && e1 != undefined)
                jest = e1.id == element.id;
        })
        if (!jest)
            nArray.push(nElement);
        dataCollector.getEventFunction("paczkiDoWyplaty", nArray)
        let colorA = '#f0ff4d';
        let colorB = '#47daff';
        let group = nElement.group;
        let id = nElement.id;
        if (!jest) {
            setTimeout(() => {
                if (parseInt(group) % 2 == 0) {
                    document.getElementById(id).style.backgroundColor = colorA;
                } else {
                    document.getElementById(id).style.backgroundColor = colorB;
                }
            }, 500)
        }
    })
}


//openWyplaty();
openLoginBox();


let mousePos = { x: 0, y: 0 }

document.onmousemove = () => {
    let contextMenu = openedViews["contextMenu"];
    mousePos.x = event.clientX;
    mousePos.y = event.clientY;
    if (contextMenu != undefined && document.getElementById(contextMenu.divID) != undefined)
        if (mousePos.x < contextMenu.left || mousePos.y < contextMenu.top || mousePos.x > contextMenu.left + document.getElementById(contextMenu.divID).offsetWidth || mousePos.y > contextMenu.top + document.getElementById(contextMenu.divID).offsetHeight) {
            closeView("contextMenu")
        }
};

function openContextMenu(parentId) {

    if (openedViews["contextMenu"] == undefined) {
        let contextMenu = new ContextMenuView("contextMenu");
        contextMenu.updateData([{title: `Pobierz PDF`, "key": "getPDF"}, {title: "Cofnij wypłatę", key:"cancelWith"}])

        openedViews["contextMenu"] = contextMenu;
        contextMenu.clickCallback = (element, object) => {
            if(element.object.key == "cancelWith"){
                //console.log("parentId", parentId)
                let pass = prompt(`Podaj hasło do usunięcia ${parentId}`, "")
            if(pass=="wyjeb"){
                sc.cancelPaczki(parentId, (ret)=>{
                    if (ret.response.status == 200) {
                        getPaczki();
                        closeView("contextMenu");
                    }
                })
            }else{
                alert("Niepoprawne hasło!")
            }


            }else if(element.object.key == "getPDF"){
                sc.getPDF({id: parentId}, ()=>{
                    //console.log("done")
                })
            }

        }
        contextMenu.setPosition(mousePos.x, mousePos.y)
    }else{
        openedViews["contextMenu"].setPosition(mousePos.x, mousePos.y)

    }

}



function getSumforCustomer(ref){
    let splittedValue = ref.split("/");
    let wartosc = parseFloat(splittedValue[1].split(";").join(".").split(",").join("."));
    let koszt = parseFloat(splittedValue[3].split(";").join(".").split(",").join("."));

    let nad = ref.toLowerCase().indexOf("nad")>=0;
    let zap = ref.toLowerCase().indexOf("zap")>=0;
    let sum = wartosc;
    if(nad){
        sum -= koszt;
    }
    return sum;
}


function convertDateFormat(date){
   // console.log(`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`)
return date.toISOString();
    //return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
}

function convertDateFormat2(date){
        let month = date.getMonth()+1;
        let day = date.getDate();
        if(month<10){
            month = "0" + month;
        }
        if(day<10){
            day = "0" + day;
        }
     return `${date.getFullYear()}-${month}-${day}`;
 }

function convertObjectDatesFormat(obj){
    if(obj['withdrawdata']!=undefined){
        obj['withdrawdata'] = convertDateFormat2(new Date(obj['withdrawdata']))
    }
    if(obj['senttime']!=undefined){
        obj['senttime'] = convertDateFormat2(new Date(obj['senttime']))
    }
    if(obj['receivetime']!=undefined){
        obj['receivetime'] = convertDateFormat2(new Date(obj['receivetime']))
    }
    return obj;
}